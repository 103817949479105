<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-row>
      <v-col cols="12">
        <base-material-card
          color="indigo"
          icon="mdi-receipt"
          inline
          class="px-5 py-3"
        >
          <template v-slot:after-heading>
            <div class="display-2 font-weight-light">
              {{ $t('orders.overview') }}
            </div>
            <v-switch
              v-model="calendar_show"
              :label="calendar_show ? $t('orders.table') : $t('orders.calendar')"
              @change="toggleCalendar"
            />
          </template>

          <v-text-field
            v-if="!calendar_show"
            v-model="search"
            append-icon="mdi-magnify"
            class="ml-auto"
            label="Search"
            hide-details
            single-line
            style="max-width: 250px;"
          />

          <v-divider
            v-if="!calendar_show"
            class="mt-3"
          />

          <v-data-table
            v-if="!calendar_show"
            :headers="headers"
            :items="this.$store.getters['order/orders']"
            :search.sync="search"
            :items-per-page="5"
            :sort-desc="[false, true]"
            multi-sort
          >
            <template
              v-slot:item.delivery_date="{ item }"
            >
              {{ item.delivery_date | datetime }}
            </template>
            <template
              v-slot:item.order_items="{ item }"
            >
              <tr
                v-for="order_item in item.order_items"
                :key="order_item"
              >
                {{ order_item.product_name }} ({{ order_item.quantity }}x)
              </tr>
            </template>
          </v-data-table>
          <v-toolbar
            v-if="calendar_show"
            flat
          >
            <calendar-btn
              v-for="(t, i) in calendar_types"
              :key="i"
              @click="calendar_type = t"
            >
              {{ $t(t) }}
            </calendar-btn>

            <v-spacer />

            <calendar-btn @click="$refs.calendar.prev()">
              <v-icon>mdi-chevron-left</v-icon>
            </calendar-btn>

            <calendar-btn @click="$refs.calendar.next()">
              <v-icon>mdi-chevron-right</v-icon>
            </calendar-btn>
          </v-toolbar>

          <v-sheet
            v-if="calendar_show"
            height="600"
            flat
            class="mt-5"
          >
            <v-calendar
              ref="calendar"
              v-model="calendar"
              :events="calendar_events"
              :type="calendar_type"
              event-color="primary"
              :event-overlap-mode="calendar_overlap"
              @click:event="viewOrder"
            >
              <template v-slot:event="{ event }">
                <div class="v-event-draggable">
                  {{ formatEventTime(event.start) }} <strong>{{ event.name }}</strong>
                </div>
              </template>
            </v-calendar>
          </v-sheet>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { VBtn } from 'vuetify/lib'

  function formatDate (oldDate) {
    var dateTime = new Date(oldDate).toLocaleString('nl-NL', { hour12: false }).split(' ')
    var dateTimeSplit = dateTime[0].split('-')
    return dateTimeSplit[2] + '-' + dateTimeSplit[1] + '-' + dateTimeSplit[0] + ' ' + dateTime[1].substring(0, 5)
  }

  export default {
    name: 'DashboardOrder',

    components: {
      CalendarBtn: {
        extends: VBtn,

        props: {
          color: {
            type: String,
            default: 'secondary',
          },
          minWidth: {
            type: Number,
            default: 0,
          },
          rounded: {
            type: Boolean,
            default: true,
          },
        },
      },
    },

    data () {
      return {
        search: undefined,
        calendar: formatDate(),
        calendar_overlap: 'column',
        calendar_events: [],
        calendar_type: 'month',
        calendar_types: [
          'month',
          'week',
          'day',
        ],
        calendar_show: this.$store.getters['viewstate/isCalendarVisible'],
      }
    },
    computed: {
      headers () {
        return [
          {
            text: this.$t('orders.headers.order_number'),
            value: 'order_number',
          },
          {
            text: this.$t('orders.headers.status'),
            value: 'status',
          },
          {
            text: this.$t('orders.headers.delivery_date'),
            value: 'delivery_date',
          },
          {
            text: this.$t('orders.headers.customer_name'),
            value: 'customer_name',
          },
          {
            text: this.$t('orders.headers.customer_note'),
            value: 'customer_note',
          },
          {
            text: this.$t('orders.headers.order_items'),
            value: 'order_items',
          },
          {
            text: this.$t('orders.headers.total'),
            value: 'total',
          },
          {
            sortable: false,
            text: this.$t('orders.headers.actions'),
            value: 'actions',
          },
        ]
      },
    },
    beforeCreate () {
      this.$store
        .dispatch('order/getOrdersV1')
        .then(response => {
          this.$store.commit('order/orders', response.data)
          this.calendar = new Date().toISOString().substring(0, 10)
          const newEvents = []
          // var deliveryDateTime = new Date()
          // var dd = []
          this.$store.getters['order/orders'].forEach((item) => {
            // deliveryDateTime = new Date(item.delivery_date).toLocaleString('nl-NL', { hour12: false }).split(' ')
            // dd = deliveryDateTime[0].split('-')
            const start = new Date(item.delivery_date)
            const end = new Date(start.getTime() + (5 * 60 * 1000))
            newEvents.push({
              id: item.order_number,
              name: '#' + item.order_number + ' ' + item.customer_name,
              start: formatDate(start),
              end: formatDate(end),
            })
          })
          this.calendar_events = newEvents
        })
        .catch(response => {
          this.$store.commit('viewstate/notify', {
            color: 'error',
            text:
              this.$t('error.orders_retrieve'),
          })
        })
        .finally(() => {
          this.loading = false
        })
    },

    methods: {
      complete (index) {
        this.list[index] = !this.list[index]
      },
      viewOrder ({ nativeEvent, event }) {
        this.$router.push({
          name: 'OrderOverview',
          params: {
            id: event.id,
          },
        })
      },
      toggleCalendar () {
        this.$store.commit('viewstate/isCalendarVisible', this.calendar_show)
      },
      formatEventTime (date) {
        return new Date(date).toLocaleTimeString('nl-NL', {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        })
      },
    },
  }
</script>

<style lang="sass">
  #calendar
    .v-calendar-weekly__day:last-child,
    .v-calendar-weekly__head-weekday:last-child
      border-right: none

    .v-calendar-weekly__week:last-child .v-calendar-weekly__day
      border-bottom: none
</style>
